import React from 'react'
import { useNavigate } from 'react-router';

export const Checkin = ({closeModal, setLocation}) => {
  const navgiate = useNavigate()
  function onClose() {
    closeModal(false);
    navgiate("/qrscan")
}
  return (
    <div className="fixed w-screen h-screen bg-[rgba(100,100,100,0.9)] top-0 left-0 overflow-auto z-90 transition ease-in-out delay-300" style={{zIndex: 99}} onClick={()=>onClose()}>
        <div className="flex justify-center items-center h-full w-full">
          <div className="flex-column mx-8 rounded-lg h-fit bg-[#d3d3d3] shadow-lg">
            <div className="flex justify-center align-center items-center content-center">
            <div className="font-semibold text-center self-center text-lg px-8 w-3/4 mt-2">
            Allow "App" to use your location?
            </div>
  
            </div>

            <div className="flex w-full flex-wrap px-6 text-sm leading-tight mt-2 text-center">
              Your precise location is used to show your position on the map, get directions, estimate travel times and improve search results
            </div>

            <img src={require('../../../src/assets/images/Map.png')} className="w-full bg-cover mt-4" />
            
            <div className="flex w-full flex-wrap">
            <button className="text-blue-400 text-center border-t-2 border-gray-400 p-2 w-full">Allow Once</button>
            <button className="text-blue-400 text-center border-t-2 border-b-2 border-gray-400 p-2 w-full">Allow While Using the App</button>
            <button className="text-blue-400 text-center border-b-2 border-gray-400 p-2 w-full rounded-b-lg">Don't Allow</button>
            </div>
          </div>
        </div>
      </div>
  )
}
