import { Skeleton, Stack } from "@mui/material";
import React from "react";

const SkeletonLoader = () => {
    return (
        <Stack className='w-full mb-4 mr-4' spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant='text' sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant='circular' className='w-10 h-10' />
            <Skeleton variant='rectangular' className='w-full h-15' />
            <Skeleton variant='rounded' height={60} />
        </Stack>
    );
};

export default SkeletonLoader;
