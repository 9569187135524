import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";

const Singpass = () => {
    const navigate = useNavigate();
    const [toggleTab, setToggleTab] = useState(false);
    return (
        <div>
            <div className='flex h-[32px]'>
                <img
                    src={require("../assets/images/merlionlogo.png")}
                    alt='singpass logo'
                    className='w-[10px] h-[10px] mx-2 self-center'
                />
                <div className='self-center'>
                    A Singapore Government Agency Website
                </div>
            </div>
            <div className='flex w-full h-[64px] bg-white justify-between'>
                <div className='flex w-1/2 justify-start'>
                    <img
                        src={require("../assets/images/singpasslogo.png")}
                        alt=''
                        className='w-full h-1/2 self-center mt-2'
                    />
                </div>
                <div className='flex w-1/2 justify-end pr-8'>
                    <MenuIcon className='self-center' />
                </div>
            </div>
            <div className='p-4'>
                <Accordion className='bg-transparent border-none shadow-none'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='text-red-500' />}
                    >
                        <Typography>
                            <b>
                                Beware of phishing calls, SMSes, emails and
                                websites
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We are aware of scammers impersonating Singpass
                            Helpdesk officers and requesting users for their
                            personal details on phone calls. Singpass Helpdesk
                            officers will never call to ask for your password
                            and 2FA details. If you are in doubt, please call
                            the official Singpass hotline at 63353533 to verify.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <div className='font-bold text-center text-2xl'>
                    Log in with Singpass
                </div>
                <div className='text-center'>Your trusted digital identity</div>
            </div>
            <div className='bg-white'>
                <div className='flex w-full px-4'>
                    <div
                        className={
                            toggleTab == false
                                ? "text-red-500 border-red-500 border-b-2 font-bold py-2 w-1/2 mx-2 text-center"
                                : "text-gray-400 border-gray-400 mx-2 font-bold py-2 w-1/2 text-center border-b-2"
                        }
                        onClick={() => setToggleTab(false)}
                    >
                        Singpass app
                    </div>
                    <div
                        className={
                            toggleTab == true
                                ? "text-red-500 border-red-500 font-bold py-2 w-1/2 text-center mx-2 border-b-2"
                                : "text-gray-400 border-gray-400 mx-2 font-bold py-2 w-1/2 text-center border-b-2"
                        }
                        onClick={() => setToggleTab(true)}
                    >
                        Password login
                    </div>
                </div>
                <div className='flex-col py-4'>
                    <div className='w-full font-semibold text-center'>
                        Tap QR code
                    </div>
                    <div className='w-full font-normal text-center'>
                        to log in with Singpass app
                    </div>
                    <div
                        className='flex align-center justify-center'
                        onClick={() => navigate("/home")}
                    >
                        <img
                            src={require("../assets/images/qrcode.png")}
                            alt='qr code for singpass'
                            className='w-3/5'
                        />
                    </div>
                </div>
            </div>
            <div className='shadow-lg p-4 text-center'>
                Don't have Singpass app?
                <div className='text-blue-500'>Download now</div>
            </div>

            <div className='absolute bottom bg-gray-700 mt-4 p-4'>
                <img
                    src={require("../assets/images/whitesingpasslogo.png")}
                    alt='singpass logo'
                    className='w-1/3 mt-2'
                />
                <div className='font-semibold text-white text-sm mt-6'>
                    Contact us | Share feedback | Read FAQs
                </div>
                <div className='font-normal text-white text-sm w-3/4 mt-1 leading-tight'>
                    Report vulnerability | Privacy statement | Terms of use |
                    Sitemap | Rate this website
                </div>
                <div className='text-white text-sm mt-4'>
                    &copy; 2022 Government of Singapore
                </div>
                <div className='text-white text-sm mt-2'>
                    Last updated on 25 August 2022
                </div>
            </div>
        </div>
    );
};

export default Singpass;
