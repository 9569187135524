import React, { Fragment } from "react";
import { useNavigate } from "react-router";

const Login = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="flex w-screen h-screen bg-cover bg-bottom bg-no-repeat bg-[url('../../src/assets/images/LoginBG.png')]">
                <div className='w-full h-full px-12'>
                    <div className='flex h-screen'>
                        <div className='m-auto'>
                            <div className='font-bold text-3xl text-white text-center'>
                                All of your sports needs at one place.
                            </div>
                            <div className='font-normal text-normal text-white text-center self-end'>
                                Get active with the new and improved ActiveSG
                                booking system
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                    onClick={() => navigate("/singpass")}
                    className='flex flex-row align-center justify-center absolute bottom-8 rounded-lg bg-white w-[90vw] mx-[5vw] py-2 font-semibold'
                >
                    Login with &nbsp; <img src={require('../assets/images/singpassbutton.png')} alt={''} className="self-center mt-1"/>
                    
                </button>
        </>
    );
};

export default Login;
