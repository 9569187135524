import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { facilityService, sportService, userService } from "../api/services";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { toast } from "react-toastify";
import HelpIcon from "@mui/icons-material/Help";
import { useEffect } from "react";
import SkeletonLoader from "../components/SkeletonLoader";
import { Skeleton, Stack } from "@mui/material";

const bannerData = [
    "https://thesmartlocal.com/wp-content/uploads/2021/04/ActiveSg-Cover-Image.jpg",
    "https://www.myactivesg.com/-/media/SSC/Consumer/Images/About-ActiveSG/Game-On-Nila/July-2021/GON-GASG-2021-Banner-700x395px-d3-310521-01-min.ashx?w=100%25&la=en&hash=9530A17EC881D1C52360720028F73A237E45869E",
    "https://activeparents.myactivesg.com/hubfs/Playground%20Editorial%20Banner.png",
];

const Home = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [nearbyFacilities, setNearbyFacilities] = useState([]);
    const [prevFacilities, setPrevFacilities] = useState([]);
    const [sports, setSports] = useState([]);

    const getUser = async () => {
        try {
            const response = await userService.getUser();
            if (response.status === 200) {
                setUser(response.data.result.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getFacilities = async () => {
        try {
            const response = await facilityService.getFacilities();
            if (response.status === 200) {
                setNearbyFacilities(response.data.result.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getSports = async () => {
        try {
            const response = await sportService.getSports();
            if (response.status === 200) {
                setSports(response.data.result.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        localStorage.setItem("status", "");
        getUser();
        getFacilities();
        getSports();
    }, []);

    useEffect(() => {
        const pFacilities = [...nearbyFacilities];
        setPrevFacilities(pFacilities.reverse());
    }, [nearbyFacilities]);

    const handleFacilClick = (id) => {
        navigate(`/booking/${id}`);
    };
    const handleHelpClick = (id) => {
        navigate(`/creditRules`);
    };

    return (
        <Fragment>
            <div className='bg-gradient-to-r from-rose-500 to-red-400 pt-4 pb-5'>
                <div className='w-full'>
                    {/* Carousel */}
                    <div className='carousel rounded-box mt-2 mx-3'>
                        {bannerData.map((banner, index) => (
                            <div
                                id={`slide${index}`}
                                key={index}
                                className='carousel-item relative w-full'
                            >
                                <img
                                    src={banner}
                                    alt='First slide'
                                    className='responsive-img h-48 w-full'
                                />
                            </div>
                        ))}
                    </div>
                    {/* End Carousel */}
                    {/* Greetings */}
                    <div className='w-full px-4 pt-2 pb-4 mt-4 rounded-md'>
                        <div className='flex justify-between text-gray-100'>
                            <div className='flex flex-col justify-center'>
                                {!user ? (
                                    <div className='w-36'>
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </div>
                                ) : (
                                    <Fragment>
                                        <p className='text-sm'>Good morning,</p>
                                        <p className='text-xl font-bold'>
                                            {user?.name}
                                        </p>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* End Greetings */}
                </div>
                {/* Search bar */}
                <div className='relative mx-4'>
                    <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                        <svg
                            aria-hidden='true'
                            className='w-5 h-5 ml-2 text-white dark:text-white'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                            ></path>
                        </svg>
                    </div>
                    <input
                        type='text'
                        placeholder='Search all facilities'
                        className=' placeholder-white text-white input-md bg-[rgb(255,255,255,0.3)] w-full rounded-full pl-14'
                    />
                </div>
                {/* End Search bar */}
            </div>

            {/* Filters */}
            <div className='flex w-full font-semibold text-base space-x-2 mt-4'>
                <div
                    className={
                        "bg-[#FAC74A] w-fit text-sm text-black px-4 py-2 ml-6 rounded-xl"
                    }
                >
                    For you
                </div>
                <div
                    className={
                        "bg-[rgb(46,64,64,0.1)] w-fit text-sm text-black px-4 py-2 rounded-xl"
                    }
                >
                    By sport
                </div>
                <div
                    className={
                        "bg-[rgb(46,64,64,0.1)] w-fit text-sm text-black px-4 py-2 rounded-xl"
                    }
                >
                    By location
                </div>
            </div>

            <div className='drop-shadow-none'>
                {/* Explore facilities */}
                <p className='mx-6 mt-5 mb-3 font-bold text-lg'>
                    Facilities near you
                </p>
                <div className='ml-6 carousel rounded-box mt-2'>
                    {nearbyFacilities.length === 0 ? (
                        <SkeletonLoader />
                    ) : (
                        nearbyFacilities.map((facility, index) => (
                            <div
                                key={facility._id}
                                className='carousel-item cursor-pointer'
                                onClick={() => handleFacilClick(facility._id)}
                            >
                                <div className='card w-60 bg-white shadow-none mb-4 mr-4'>
                                    <figure>
                                        <img
                                            className='h-32 w-full'
                                            src={facility.imgUrl}
                                            alt='facility'
                                        />
                                    </figure>
                                    <div className='pl-5 pt-2'>
                                        <p className='font-semibold text-lg pt-2'>
                                            {facility.sport?.name}
                                        </p>
                                        <p className='text-sm text-gray-600'>
                                            {facility.name}
                                        </p>
                                        <div className='flex pt-1 pb-2'>
                                            <LocationOnOutlinedIcon className='-ml-1 text-primary' />
                                            <p className='my-auto text-sm text-gray-600'>
                                                {index === 0
                                                    ? 480
                                                    : Math.floor(
                                                          Math.random() * 999
                                                      ) + 500}
                                                m away
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                {/* End Explore facilities */}
                {/* Previously booked */}
                <p className='mx-6 mb-3 font-bold text-lg'>Previously booked</p>
                <div className='ml-6 carousel rounded-box mt-2'>
                    {prevFacilities.length === 0 ? (
                        <SkeletonLoader />
                    ) : (
                        prevFacilities.map((facility) => (
                            <div
                                key={facility._id}
                                className='carousel-item cursor-pointer'
                                onClick={() => handleFacilClick(facility._id)}
                            >
                                <div className='card w-60 bg-white shadow-none mb-4 mr-4'>
                                    <figure>
                                        <img
                                            className='h-32 w-full'
                                            src={facility.imgUrl}
                                            alt='facility'
                                        />
                                    </figure>
                                    <div className='pl-5 pt-2 pb-2'>
                                        <p className='font-semibold text-lg pt-2'>
                                            {facility.sport?.name}
                                        </p>
                                        <p className='text-sm text-gray-600'>
                                            {facility.name}
                                        </p>
                                        <p className='text-sm text-gray-600'>
                                            Wednesday | 8.00am
                                        </p>
                                        <p className='pt-2 text-sm font-medium text-red-600'>
                                            Book again{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Home;
