import React from "react";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';

function RatingHistory() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/profile");
  };

  const handleLearnClick = () => {
    navigate("/creditrules")
  }

  return (
    <div className="mx-5">
      <div className="flex flex-row pt-8" >
        <ChevronLeftIcon className="w-[32px] h-[32px] text-[#2E4054]" onClick={() => handleBack()}/>
        <span className="font-bold text-xl ml-1 self-center">
          {" "}
          Rating History{" "}
          
        </span>
        <span className="absolute right-[20px] mt-1 text-indigo-500" onClick={() => handleLearnClick()}>Learn more <HelpIcon className="mb-1"/></span>
      </div>

      <div className="flex flex-wrap w-full my-4 space-y-2">
        <div className="flex flex-row w-full bg-white rounded-lg p-4">
          <ThunderstormIcon className="self-center bg-[#DAF0EB] rounded-full p-2 w-[40px] h-[40px] text-[#46B39C]" />
          <div className="flex flex-col text-sm ml-4">
            <span className="font-bold">Wet Weather Waiver</span>
            <span>19 Oct 2022</span>
          </div>
          <div className="absolute right-[40px] text-[#46B39C] font-xl font-bold self-center">
            +0.3
          </div>
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg p-4">
          <DoDisturbAltIcon className="self-center bg-[#F9DBD7] rounded-full p-2 w-[40px] h-[40px] text-[#E24A35]" />
          <div className="flex flex-col text-sm ml-4">
            <span className="font-bold">No Show</span>
            <span>19 Oct 2022</span>
          </div>
          <div className="absolute right-[40px] text-[#E24A35] font-xl font-bold self-center">
            -0.3
          </div>
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg p-4">
          <ScheduleIcon className="self-center bg-[#DAF0EB] rounded-full p-2 w-[40px] h-[40px] text-[#46B39C]" />
          <div className="flex flex-col text-sm ml-4">
            <span className="font-bold">Punctual Check-in</span>
            <span>19 Oct 2022</span>
          </div>
          <div className="absolute right-[40px] text-[#46B39C] font-xl font-bold self-center">
            +0.3
          </div>
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg p-4">
          <CalendarMonthIcon className="self-center bg-[#DAF0EB] rounded-full p-2 w-[40px] h-[40px] text-[#46B39C]" />
          <div className="flex flex-col text-sm ml-4">
            <span className="font-bold">Non-Peak Booking</span>
            <span>14 Oct 2022</span>
          </div>
          <div className="absolute right-[40px] text-[#46B39C] font-xl font-bold self-center">
            +0.3
          </div>
        </div>

        <div className="flex flex-row w-full bg-white rounded-lg p-4">
          <RunningWithErrorsIcon className="self-center bg-[#F9DBD7] rounded-full p-2 w-[40px] h-[40px] text-[#E24A35]" />
          <div className="flex flex-col text-sm ml-4">
            <span className="font-bold">Late Check-in</span>
            <span>19 Oct 2022</span>
          </div>
          <div className="absolute right-[40px] text-[#E24A35] font-xl font-bold self-center">
            -0.1
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingHistory;
