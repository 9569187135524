import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {Checkin} from "../components/ConfirmBooking/Checkin";
import { ConfirmLocation } from "../components/ConfirmBooking/ConfirmLocation";
 
export const Location = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalLocationOpen, setModalLocationOpen] = React.useState(false);
    const [modalLocation, setModalLocation] = React.useState(false);
    

    function onHandleOpen(){
        setModalOpen(true);
        setModalLocationOpen(true);
    }
  return (
    <div>
      <img
        src={require("../../src/assets/images/Street.png")}
        className="bg-cover w-full h-full"
      />
      <div className="bg-white p-8">
        <div className="font-bold text-lg">Check-In</div>
        <div className="text-gray-500 text-sm my-2">Your current location</div>
        <div className="flex w-full">
          <LocationOnOutlinedIcon className="rounded-full bg-[#FFEAEA] text-[#C91C1C] self-center text-4xl" />
          <div className="flex-column flex-nowrap pl-2">
            <div>ActiveSG Our Tampines Hub</div>
            <div className="text-sm">1 Tampines Walk, #07-31, Singapore 528523</div>
          </div>
        </div>

        <button className="w-full rounded-lg text-white bg-[#DB4A4A] p-4 my-4" onClick={()=>onHandleOpen()}>Confirm check-in</button>
        <div className="text-center">
            Cancel
        </div>
      </div>
      {modalOpen && !modalLocation && <Checkin closeModal={setModalOpen} setLocation={setModalLocation}/>}
    </div>
  );
};
