import React from "react";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useNavigate } from "react-router-dom";

function WeeklyLimit() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/profile");
  };
  return (
    <div className="mx-5">
        <div className="flex flex-row pt-8" onClick={() => handleBack()}>
            <ChevronLeftIcon className="w-[32px] h-[32px] text-[#2E4054]" />
          <span className="font-bold text-xl ml-1">Weekly Limit</span>
          </div>
          <div className='bg-yellow-100 h-auto mt-2 px-4 py-4'>
                <div className='flex flex-col'>
                    <div className='flex'>
                        <span className=" text-warning"><ScheduleIcon/></span>
                        <p className='text-sm self-center ml-2'> Your weekly limits reset in <span className = "font-bold"> 6 days.</span> </p>
                    </div>
                </div>
            </div>
      <div className="flex flex-wrap w-full my-4 space-y-2">
        <div className="flex flex-row w-full bg-white rounded-lg p-4 ">
          <SportsTennisIcon className="self-center bg-[rgba(46,64,84,0.1)] rounded-full p-2 w-[40px] h-[40px] text-[#2E4054]" />
          <div className="font-xl ml-4 font-bold self-center">Badminton</div>
          <div className="flex flex-col text-sm absolute text-end right-[40px]">
            <span className="font-semibold">120 mins</span>
            <span className="text-sm">of 120 mins left</span>
          </div>
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg p-4 ">
          <SportsBasketballIcon className="self-center bg-[rgba(46,64,84,0.1)] rounded-full p-2 w-[40px] h-[40px] text-[#2E4054]" />
          <div className="ml-4 font-xl font-bold self-center">Basketball</div>
          <div className="flex flex-col text-sm absolute text-end right-[40px]">
            <span className="font-semibold">60 mins</span>
            <span className="text-sm">of 120 mins left</span>
          </div>
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg p-4">
          <FitnessCenterIcon className="self-center bg-[rgba(46,64,84,0.1)] rounded-full p-2 w-[40px] h-[40px] text-[#2E4054]" />
          <div className="ml-4 font-xl font-bold self-center">Gym</div>
          <div className="flex flex-col text-sm absolute text-end right-[40px]">
            <span className="font-semibold text-red-600">0 mins</span>
            <span className="text-sm">of 120 mins left</span>
          </div>
        </div>

        <div className="text-sm text-center pt-4">
          Weekly quotas for activities and sports that are not reflected in the
          list below have yet to be utilised.
        </div>
      </div>
    </div>
  );
}

export default WeeklyLimit;
