import React, { useEffect } from "react";

const Timeslots = ({ courtId, time, selected, handleSelectSlot }) => {
    const handleSelect = (event) => {
        event.preventDefault();
        const slotId = event.target.value;
        handleSelectSlot(courtId, slotId);
    };

    return (
        <button
            className={`btn rounded-full  ${!selected && "btn-outline"} ${
                time.isPopular
                    ? "btn-warning"
                    : "border-gray-700 hover:bg-gray-700 hover:text-white "
            }`}
            value={time._id}
            onClick={handleSelect}
        >
            {time.time}
            {time.isPopular && <span className='text-warning'>*</span>}
        </button>
    );
};

export default Timeslots;
