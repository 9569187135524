import React from "react";
import MovingIcon from "@mui/icons-material/Moving";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import Groups2Icon from "@mui/icons-material/Groups2";
import StarsIcon from "@mui/icons-material/Stars";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const CreditRules = () => {
  return (
    <div className="mx-auto">
      <div className="bg-indigo-50">
        <div className="grid-rows-2">
          <div className="flex items-center mx-2 px-8  pt-8">
            <div className="grid justify-items-center w-10 h-10 bg-indigo-100 rounded-full mr-3 text-center content-center">
              <AnnouncementOutlinedIcon className="fill-indigo-250 text-2xl text-center justify-self-center	"></AnnouncementOutlinedIcon>
            </div>
            <h1 className=" text-xl text-left">
              Changes to the Booking System
            </h1>
          </div>
          <div className="flex mx-2 pl-8 mr-5 pb-5">
            <div className="mx-6"></div>
            <p className="text-base text-left ">
              The revamped ActiveSG booking system now introduces measures to
              ensure a fairer and stress-free facility booking experience for
              all!
            </p>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <div className="flex items-center mx-4 mt-4">
          <StarsIcon className="fill-red-500 text-lg mr-1" />
          <h2 className="text-xl text-red-500 ">
            <b>User Rating</b>
          </h2>
        </div>
        <div className="flex justify-center mx-4"></div>
        <p className="text-sm ml-4 mr-4 mb-4 mt-1 text-left">
          Every user will now have a user rating system along with their
          profile. New users will start off with 3 points and points will be
          added and deducted based on the user’s booking habits as follows:
        </p>
        <p className="text-base ml-4 mr-4 mb-4 mt-4 text-base">
          <b>Earning of Points:</b>
        </p>
        <ul className="text-base ml-8 mr-4 mb-4 mt-4">
          <li className="flex">
            <MovingIcon className="fill-green-500 mr-2 "></MovingIcon>
            <p className="text-sm">
              <b className="text-green-500">(+0.1)</b> Booking of non peak-hour
              timeslots
            </p>
          </li>
          <li className="flex">
            <MovingIcon className="fill-green-500 mr-2"></MovingIcon>
            <p className="text-sm">
              <b className="text-green-500">(+0.2)</b> Turning up on time for
              bookings
            </p>
          </li>
        </ul>
        <p className="text-base ml-4 mr-4 mb-4 mt-4">
          <b>Deduction of Points:</b>
        </p>
        <ul className=" ml-8 mr-4 mb-4 mt-4">
          <li className="flex">
            <TrendingDownIcon className="fill-yellow-500 mr-2" />
            <p className="text-sm">
              <b className="text-yellow-500">(-0.1)</b> Late cancellation
              (&lt;24h prior)
            </p>
          </li>
          <li className="flex">
            <TrendingDownIcon className="fill-yellow-500 mr-2"></TrendingDownIcon>
            <p className="text-sm">
              <b className="text-yellow-500">(-0.2)</b> Late check-in to
              bookings
            </p>
          </li>
          <li className="flex">
            <TrendingDownIcon className="fill-yellow-500 mr-2"></TrendingDownIcon>
            <p className="text-sm">
              <b className="text-yellow-500">(-0.3)</b> No-show for bookings
            </p>
          </li>
        </ul>
        <hr className="mx-4"></hr>
        <div className="flex items-center mx-4 mt-4">
          <WatchLaterIcon className="fill-red-500 text-lg mr-1" />
          <h2 className="text-xl text-red-500 ">
            <b>Waiting List</b>
          </h2>
        </div>
        <p className="text-sm ml-4 mr-4 mb-2 mt-1 text-left">
          For popular timeslots, the booking system automatically enables a
          waiting list. The system will allocate the slot to a user based on
          these following factors:
        </p>
      </div>
      <div class="flex  mx-3">
        <div class="w-1/3 text-center  block p-4 rounded-lg shadow-nonemax-w-sm">
          <div className="flex justify-between">
            <div className="text-center">
              <Groups2Icon className="fill-green-500" />
              <p class="pt-1 font-bold text-green-500">
                First Come First Serve
              </p>
            </div>
          </div>
        </div>
        <div class="w-1/3 text-center  block p-4 rounded-lg none-lg max-w-sm">
          <div className="flex justify-between">
            <div className="text-center">
              <StarsIcon className="fill-yellow-500" />
              <p class="pt-1 font-bold text-yellow-500">User Ratings</p>
            </div>
          </div>
        </div>
        <div class="w-1/3 text-center  block p-4 rounded-lg none-lg max-w-sm">
          <div className="flex justify-between">
            <div className="text-center ">
              <CalendarMonthIcon className="fill-red-500" />
              <p class="pt-1 font-bold text-red-500">Last Booking</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditRules;
