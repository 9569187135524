import React, {useState} from 'react'
import { History } from '../components/MyBookings/History';
import { Pending } from '../components/MyBookings/Pending';
import { Upcoming } from '../components/MyBookings/Upcoming';

export const MyBookings = () => {
    const [selectedMenu, setSelectedMenu] = useState('upcoming');
  return (
    <div className='mx-5'>
        <div className='font-bold text-xl pt-8'>My Bookings</div>
        <div className='flex w-full font-semibold text-base space-x-2 mt-4'>
            <div className={selectedMenu == 'upcoming' ? 'bg-[#FAC74A] w-fit text-sm text-black px-5 py-2 rounded-xl':'bg-[rgb(46,64,64,0.1)] w-fit text-sm text-black px-5 py-2 rounded-xl'} onClick={()=>setSelectedMenu('upcoming')}>Upcoming</div>
            <div className={selectedMenu == 'pending' ? 'bg-[#FAC74A] w-fit text-sm text-black px-5 py-2 rounded-xl':'bg-[rgb(46,64,64,0.1)] w-fit text-sm text-black px-5 py-2 rounded-xl'} onClick={()=>setSelectedMenu('pending')}>Pending</div>
            <div className={selectedMenu == 'history' ? 'bg-[#FAC74A] w-fit text-sm text-black px-5 py-2 rounded-xl':'bg-[rgb(46,64,64,0.1)] w-fit text-sm text-black px-5 py-2 rounded-xl'} onClick={()=>setSelectedMenu('history')}>History</div>
        </div>
        

        <div className="flex w-full my-4">
        {selectedMenu == 'upcoming' && <Upcoming/>}
        {selectedMenu == 'pending' && <Pending/>}
        {selectedMenu == 'history' && <History/>}
        </div>
        
    </div>
  )
}
