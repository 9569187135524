import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { userService } from "../api/services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  //   const getUser = async () => {
  //     try {
  //       const response = await userService.getUser();
  //       if (response.status === 200) {
  //         console.log(response.data.result.data);
  //         setUser(response.data.result.data);
  //       }
  //     } catch (error) {
  //       toast.error(error);
  //       toast.error("Error fetching users");
  //     }
  //   };

  useEffect(() => {
    // getUser();
  }, []);

  const handleRatingsClick = () => {
    navigate("/ratinghistory");
  };

  const handleLimitClick = () => {
    navigate("/weeklylimit");
  };

  return (
    <div className="mx-auto">
      <div className="relative py-4 ">
        <div className="mx-6 mt-6 mb-3">
          <h1 className="text-xl font-bold">My Profile</h1>
        </div>
        <div className="mx-3">
          <div class="flex -mb-10">
            <div
              class="bg-indigo-50 w-1/2 tcenterext- mx-2 block p-4 rounded-lg shadow-nonemax-w-sm"
              onClick={() => handleRatingsClick()}
            >
              <div className="flex justify-between">
                <div className="text-left">
                  <p class="text-sm mt-1">User Rating</p>
                  <p class="text-2xl font-bold">3.5</p>
                </div>
                <div className="grid content-center">
                  <ChevronRightIcon
                    fontSize="medium"
                    className="w-6 h-6 text-indigo-500 -mr-2"
                  ></ChevronRightIcon>
                </div>
              </div>
            </div>
            <div
              class="bg-indigo-50 w-1/2 text-center mx-2 block p-4 rounded-lg none-lg max-w-sm"
              onClick={() => handleLimitClick()}
            >
              <div className="flex justify-between">
                <div className="text-left">
                  <p class="text-sm mt-1">View your</p>
                  <p class="pt-1 font-bold">Weekly Limit</p>
                </div>
                <div className="grid content-center">
                  <ChevronRightIcon
                    fontSize="medium"
                    className="w-6 h-6 text-indigo-500 -mr-2"
                  ></ChevronRightIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-6 py-4 overflow-visible">
        <h5 className="mt-6 text-lg text-gray-500 py-4 font-medium">
          Settings
        </h5>
        <div className="flex py-2 justify-between ">
          <h5 className="text-lg font-medium">
            Personal Information
          </h5>
        </div>
        <hr className="py-2"></hr>
        <div className="flex py-2 justify-between ">
          <h5 className="text-lg font-medium">Change Language</h5>
        </div>
        <hr className="py-2"></hr>
        <div className="flex py-2 justify-between ">
          <h5 className="text-lg font-medium">
            Enable Biometric Authentication
          </h5>
        </div>
        <hr className="py-2"></hr>
        <div className="flex py-2 justify-between ">
          <h5 className="text-lg font-medium">Notifications</h5>
        </div>
        <hr className="py-2"></hr>
      </div>
      <div className="mx-4">
        <button className="btn w-full btn-primary text-white rounded-md ">
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Profile;
