import React, { Fragment, useEffect } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { Skeleton, SwipeableDrawer } from "@mui/material";
import moment from "moment/moment";
import Timeslots from "../components/Booking/Timeslots";
import { toast } from "react-toastify";
import { facilityService, timeslotService, userService } from "../api/services";
import { useParams } from "react-router";
import SkeletonLoader from "../components/SkeletonLoader";
import { useNavigate } from "react-router-dom";

const Booking = () => {
    const params = useParams();
    const { id: facilityId } = params;
    const today = new Date();
    const monthAbr = today.toLocaleString("default", { month: "short" });
    const year = today.getFullYear();
    const lastDay = moment().add(5, "days").toDate();
    const tenDaysLater = moment().add(3, "days").toDate();
    const dates = [];
    const navigate = useNavigate();
    // console.log("month abr", monthAbr)
    // console.log("tenDaysLater",tenDaysLater.getDate())
    // for (let i = tenDaysLater.getDate(); i <= lastDay.getDate(); i++) {
    //     dates.push({
    //         day: moment(`${i} ${monthAbr} ${year}`, "DD MMM YYYY").format(
    //             "ddd"
    //         ),
    //         date: moment(`${i} ${monthAbr} ${year}`, "DD MMM YYYY").toDate(),
    //     });
    // }
    for (let i = 1; i <= 5; i++) {
        if (i == 0) {
            dates.push({
                day: moment(
                    `${i + 31} ${monthAbr} ${year}`,
                    "DD MMM YYYY"
                ).format("ddd"),
                date: moment(
                    `${i + 31} ${monthAbr} ${year}`,
                    "DD MMM YYYY"
                ).toDate(),
            });
        } else {
            dates.push({
                day: moment(`${i} NOV ${year}`, "DD MMM YYYY").format("ddd"),
                date: moment(`${i} NOV ${year}`, "DD MMM YYYY").toDate(),
            });
        }
    }

    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openCollapse, setOpenCollapse] = React.useState(true);
    const [selectedDate, setSelectedDate] = React.useState(dates[0].date);
    const [showWaitingList, setShowWaitingList] = React.useState(false);
    const [disableBtn, setdisableBtn] = React.useState(true);
    const [disableCfmBtn, setdisableCfmBtn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [timeRemaining, setTimeRemaining] = React.useState(0);
    const [facility, setFacility] = React.useState(null);
    const [courts, setCourts] = React.useState([]);
    const [bookings, setBookings] = React.useState([]);
    const [allTimeslots, setAllTimeslots] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [octoberStatus, setOctoberStatus] = React.useState(true);

    const getUser = async () => {
        try {
            const response = await userService.getUser();
            if (response.status === 200) {
                const userDetails = response.data.result.data;
                setUser(userDetails);
                setTimeRemaining(userDetails?.timeRemaining);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getTimeslots = async () => {
        try {
            setLoading(true);
            const response = await facilityService.getAvailableTimeslots(
                facilityId,
                moment(selectedDate).format("DD MMM YYYY")
            );
            if (response.status === 200) {
                const data = response.data.result.data;
                setCourts(data);
                setBookings(
                    data.map((c) => {
                        return {
                            court: c.court._id,
                            courtObj: c.court,
                            bookedBy: user?._id ?? "63479a4a59e7ef146d94934e",
                            bookingDate: selectedDate,
                            bookingTimeSlots: [],
                        };
                    })
                );
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getFacility = async () => {
        try {
            const response = await facilityService.getFacility(facilityId);
            if (response.status === 200) {
                const data = response.data.result.data;
                setFacility(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getAllTimeslots = async () => {
        try {
            const response = await timeslotService.getAllTimeslots(
                selectedDate
            );
            if (response.status === 200) {
                const data = response.data.result.data;
                setAllTimeslots(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleBook = () => {
        const numOfSelectedSlots = getNumOfSelectedSlots();
        if (user?.timeRemaining - numOfSelectedSlots * 60 < 0) {
            toast.error("You have exceeded the time limit");
            return;
        }
        setShowWaitingList(containsPopularSlots());
        setOpenDrawer(true);
    };

    const handleClickDrawer = (event) => {
        // close drawer if not click on button
        if (event.target.tagName !== "BUTTON") {
            setOpenDrawer(false);
        }
    };

    const handleSelectDate = (date) => {
        if (date.getDate() == "31") {
            setOctoberStatus(true);
        } else {
            setOctoberStatus(false);
        }
        setSelectedDate(date);
    };

    const handleSelectSlot = (courtId, slotId) => {
        // Update courts to highlight selected slot
        const newCourts = [...courts];
        const court = newCourts.find((c) => c.court._id === courtId);
        const time = court.availableTime.find((t) => t._id === slotId);
        if (time) {
            time.selected = !time.selected;
        }
        setCourts(newCourts);

        // Update bookings
        const newbookings = [...bookings];
        const booking = newbookings.find((t) => t.court === courtId);
        if (booking.bookingTimeSlots.includes(slotId)) {
            booking.bookingTimeSlots = booking.bookingTimeSlots.filter(
                (t) => t !== slotId
            );
        } else {
            booking.bookingTimeSlots.push(slotId);
        }
        setBookings(newbookings);
    };

    const handleConfirmBooking = async () => {
        const confirmedBookings = getConfirmedBookings();
        const numOfSelectedSlots = getNumOfSelectedSlots();
        if (user?.timeRemaining - numOfSelectedSlots * 60 < 0) {
            toast.error("You have exceeded the time limit");
            return;
        }

        try {
            setdisableCfmBtn(true);
            const response = await facilityService.bookFacility(
                facilityId,
                confirmedBookings
            );

            if (response.status === 200) {
                toast.success("Booking successful");
                setOpenDrawer(false);
                getUser();
                getTimeslots();
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.result.message);
        }
        setdisableCfmBtn(false);
    };

    useEffect(() => {
        getUser();
        getFacility();
        getTimeslots();
        getAllTimeslots();
    }, []);

    useEffect(() => {
        getUser();
        getTimeslots();
        getAllTimeslots();
    }, [selectedDate]);

    useEffect(() => {
        const selectedSlots = getNumOfSelectedSlots();
        if (selectedSlots > 0) {
            setdisableBtn(false);
        } else {
            setdisableBtn(true);
        }
        calculateRemainingTiming();
        if (containsPopularSlots()) {
            setShowWaitingList(true);
            setOpenDrawer(true);
        }
    }, [bookings]);

    const getNumOfSelectedSlots = () => {
        let count = 0;
        for (const booking of bookings) {
            count += booking.bookingTimeSlots.length;
        }
        return count;
    };

    const calculateRemainingTiming = () => {
        const numOfSelectedSlots = getNumOfSelectedSlots();
        const newTimeRemaining = user?.timeRemaining - numOfSelectedSlots * 60;
        if (newTimeRemaining >= 0) {
            setTimeRemaining(newTimeRemaining);
        }
    };

    const containsPopularSlots = () => {
        for (const court of courts) {
            const selectedPopularSlots = court.availableTime.filter(
                (t) => t.isPopular && t.selected
            );
            if (selectedPopularSlots.length > 0) return true;
        }
        return false;
    };

    const getBookingTimeStr = (selectedSlots) => {
        const startTimeStr = allTimeslots.find(
            (t) => t._id === selectedSlots[0]
        )?.time;
        const endTimeStr = allTimeslots.find(
            (t) => t._id === selectedSlots[selectedSlots.length - 1]
        )?.time;
        return selectedSlots.length === 1
            ? startTimeStr
            : `${startTimeStr} - ${endTimeStr}`;
    };

    const getConfirmedBookings = () => {
        const confirmedBookings = [];
        for (const booking of bookings) {
            if (booking.bookingTimeSlots.length > 0) {
                confirmedBookings.push(booking);
            }
        }
        return confirmedBookings;
    };

    const joinWaitlist = () => {
        const confirmedBookings = getConfirmedBookings();

        return (
            <Fragment>
                <h2 className='text-xl font-bold'>Popular Slot</h2>
                <div className='bg-yellow-100 mt-4 px-6 py-4'>
                    <div className='flex flex-col'>
                        <div className='flex text-warning'>
                            <ErrorOutlineOutlinedIcon />
                            <p className='ml-1 text-sm font-semibold text-left'>
                                Waiting list has been enabled for the selected
                                slot
                            </p>
                        </div>
                        <p className='text-sm mt-1 ml-7 text-left'>
                            You will be notified via e-mail if your booking has
                            been successful within 5 days prior to the release
                            of booking slot.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col justify-center space-y-2 my-3'>
                    {confirmedBookings.map((booking, index) => (
                        <Fragment key={index}>
                            {index > 0 && <hr />}
                            <div
                                key={index}
                                className='flex flex-col justify-center space-y-1'
                            >
                                <div className='flex space-x-1'>
                                    <CalendarTodayOutlinedIcon className='pr-1 text-primary' />
                                    <p>
                                        {moment(selectedDate).format(
                                            "ddd DD MMM YYYY"
                                        )}
                                    </p>
                                </div>
                                <div className='flex space-x-1'>
                                    <AccessTimeOutlinedIcon className='pr-1 text-primary' />
                                    <p>
                                        {getBookingTimeStr(
                                            booking.bookingTimeSlots
                                        )}
                                    </p>
                                </div>
                                <div className='flex space-x-1'>
                                    <LocationOnOutlinedIcon className='pr-1 text-primary' />
                                    <p>
                                        {facility?.name},{" "}
                                        {booking.courtObj.name}
                                    </p>
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
                <button
                    className={`mt-1 mb-3 btn btn-primary w-full text-white ${
                        disableCfmBtn && "btn-disable"
                    }`}
                    onClick={handleConfirmBooking}
                >
                    Join waiting list
                </button>
                <p className='text-xs font-bold text-primary mb-4'>
                    Learn more about the waiting list system
                </p>
                <hr />
                <div className='flex flex-col text-left my-4 mx-2'>
                    <p className='text-base-content text-lg font-bold'>
                        Earn more credits
                    </p>
                    <p className='text-base-content text-sm'>
                        Return to the previous screen and opt for slots during
                        non peak-hours to earn booking credits.
                    </p>
                </div>
                <hr />
                <div className='flex flex-col text-left my-4'>
                    <p className='text-base-content text-lg font-bold mx-2'>
                        Skip the queue
                    </p>
                    <p className='text-base-content text-sm mx-2'>
                        Facilities near you with available slots on the same day
                    </p>
                    <div className='carousel rounded-box mt-3 -mx-4 ml-2 '>
                        <div className='carousel-item'>
                            <div className='card w-60 bg-white shadow-lg mb-4 mr-4'>
                                <figure>
                                    <img
                                        className='h-32 w-full'
                                        src='https://www.myactivesg.com/-/media/SSC/Consumer/Images/Facilities/Yio-Chu-Kang-Sports-Hall/Yio-Chu-Kang-Sports-Hall2.ashx'
                                        alt='Sports hall'
                                    />
                                </figure>
                                <div className='pl-5 pt-2'>
                                    <p className='font-semibold text-lg pt-2'>
                                        Badminton
                                    </p>
                                    <p className='text-sm text-gray-600'>
                                        Yio Chu Kang Sports Hall
                                    </p>
                                    <div className='flex pt-1'>
                                        <LocationOnOutlinedIcon className='-ml-1 text-primary' />
                                        <p className='my-auto text-sm text-gray-600'>
                                            500m away
                                        </p>
                                    </div>
                                    <div className='flex pt-2 pb-4'>
                                        <span className='bg-green-100 text-green-700 rounded-full px-2 py-1 mr-1 text-sm font-semibold'>
                                            7:30am
                                        </span>
                                        <span className='bg-green-100 text-green-700 rounded-full px-2 py-1 text-sm font-semibold'>
                                            8:00am
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='carousel-item'>
                            <div className='card w-60 bg-white shadow-lg mb-4 mr-4'>
                                <figure>
                                    <img
                                        className='h-32 w-full'
                                        src='https://www.myactivesg.com/-/media/SSC/Consumer/Images/Facilities/Yio-Chu-Kang-Sports-Hall/Yio-Chu-Kang-Sports-Hall2.ashx'
                                        alt='Sports hall'
                                    />
                                </figure>
                                <div className='pl-5 pt-2'>
                                    <p className='font-semibold text-lg pt-2'>
                                        Badminton
                                    </p>
                                    <p className='text-sm text-gray-600'>
                                        Ang Mo Kio Sports Hall
                                    </p>
                                    <div className='flex pt-1'>
                                        <LocationOnOutlinedIcon className='-ml-1 text-primary' />
                                        <p className='my-auto text-sm text-gray-600'>
                                            1.2km away
                                        </p>
                                    </div>
                                    <div className='flex pt-2 pb-4'>
                                        <span className='bg-green-100 text-green-700 rounded-full px-2 py-1 mr-1 text-sm font-semibold'>
                                            8:00am
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const confirmBooking = () => {
        const confirmedBookings = getConfirmedBookings();

        return (
            <Fragment>
                <h2 className='text-xl font-bold mb-4'>Confirm Booking</h2>
                <div className='flex flex-col justify-center space-y-4'>
                    {confirmedBookings.map((booking, index) => (
                        <Fragment key={index}>
                            {index > 0 && <hr />}
                            <div
                                key={index}
                                className='flex flex-col justify-center space-y-2'
                            >
                                <div className='flex space-x-1'>
                                    <CalendarTodayOutlinedIcon className='text-primary' />
                                    <p>
                                        {moment(selectedDate).format(
                                            "ddd DD MMM YYYY"
                                        )}
                                    </p>
                                </div>
                                <div className='flex space-x-1'>
                                    <AccessTimeOutlinedIcon className='text-primary' />
                                    <p>
                                        {getBookingTimeStr(
                                            booking.bookingTimeSlots
                                        )}
                                    </p>
                                </div>
                                <div className='flex space-x-1'>
                                    <LocationOnOutlinedIcon className='text-primary' />
                                    <p>
                                        {facility?.name},{" "}
                                        {booking.courtObj.name}
                                    </p>
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
                <button
                    className={`btn btn-primary mt-4 w-full text-white rounded-md ${
                        disableCfmBtn && "btn-disabled"
                    }`}
                    onClick={handleConfirmBooking}
                >
                    Confirm Booking
                </button>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div
                className='relative image-full'
                style={{
                    height: "25vh",
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.75)), url(${facility?.imgUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className='absolute top-12 ml-4'>
                    {!facility ? (
                        <div className='w-40'>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    ) : (
                        <Fragment>
                            <div className='badge badge-lg badge-primary font-bold mb-2'>
                                {facility?.sport.name}
                            </div>
                            <div className='text-white'>
                                <p className='text-lg font-bold'>
                                    {facility?.name}
                                </p>
                                <p className='text-sm'>{facility?.address}</p>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>

            <div className='collapse collapse-arrow border border-base-300 bg-base-100'>
                <input
                    type='checkbox'
                    checked={openCollapse}
                    onChange={() => setOpenCollapse(!openCollapse)}
                />
                <div className='collapse-title text-md font-medium'>
                    <div className='flex flex-col mr-10 space-y-1'>
                        <div>
                            <p className='font-light pb-1 text-sm text-gray-500'>
                                Relevant to you
                            </p>
                        </div>
                        <div className='flex'>
                            <LocationOnOutlinedIcon className='text-primary' />
                            <p className='pl-1'>500m</p>
                        </div>
                        <div className='flex'>
                            <TimerOutlinedIcon className='text-primary' />
                            <p className='pl-1'>
                                {timeRemaining} min left this week
                            </p>
                        </div>
                    </div>
                </div>
                <div className='collapse-content border-t-2'>
                    <p className='mt-4 pb-1 font-light text-sm text-gray-500'>
                        Book a timeslot
                    </p>
                    <p className='font-semibold text-error'>{"Nov 2022"}</p>
                    <div className='carousel mt-2 -mx-4 ml-1'>
                        {dates.map((date, index) => (
                            <div key={index} className='carousel-item'>
                                <button
                                    className={`btn-square btn-lg mr-2 ${
                                        selectedDate.getDate() ===
                                        date.date.getDate()
                                            ? "bg-primary rounded-md text-white"
                                            : "text-gray-600"
                                    }`}
                                    onClick={() => handleSelectDate(date.date)}
                                >
                                    <div className='flex flex-col justify-center items-center '>
                                        <p>{date.day}</p>
                                        <p className='font-bold'>
                                            {date.date.getDate()}
                                        </p>
                                    </div>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {(() => {
                if (courts.length !== 0) {
                    return (
                        <div className='bg-yellow-100 h-20 mt-2 px-6 py-4'>
                            <div className='flex flex-col'>
                                <div className='flex text-warning'>
                                    <ErrorOutlineOutlinedIcon />
                                    <p className='ml-1 font-semibold'>
                                        Popular slots
                                    </p>
                                </div>
                                <p className='text-sm'>
                                    Waiting list has been enabled for popular
                                    slots.
                                </p>
                            </div>
                        </div>
                    );
                }
            })()}

            <div className='mt-4 mx-5'>
                <div className='space-y-4'>
                    {loading && <SkeletonLoader />}
                    {!loading && courts.length === 0 ? (
                        <p className='text-center'>
                            There are currently no available slots for this
                            date.
                        </p>
                    ) : (
                        courts?.map((court, index) => (
                            <div key={index}>
                                <h2 className='font-semibold text-lg mt-5 mb-3'>
                                    {court.court.name}
                                </h2>
                                {loading ? (
                                    <SkeletonLoader />
                                ) : (
                                    <div className='grid grid-cols-3 gap-3'>
                                        {court.availableTime?.map(
                                            (time, index) => (
                                                <Timeslots
                                                    key={index}
                                                    courtId={court.court._id}
                                                    time={time}
                                                    selected={
                                                        time.selected ?? false
                                                    }
                                                    handleSelectSlot={
                                                        handleSelectSlot
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
                <div className='flex justify-center mt-6 '>
                    <button
                        className={`btn w-full btn-primary text-white rounded-md ${
                            disableBtn && "btn-disabled"
                        }`}
                        onClick={handleBook}
                    >
                        Book slot
                    </button>
                </div>
            </div>
            <SwipeableDrawer
                anchor='bottom'
                open={openDrawer}
                PaperProps={{
                    sx: {
                        borderTopLeftRadius: "0.8rem",
                        borderTopRightRadius: "0.8rem",
                    },
                }}
                onOpen={() => setOpenDrawer(true)}
                onClose={() => setOpenDrawer(false)}
                onClick={handleClickDrawer}
            >
                <div className='flex justify-center py-2'>
                    <div className='w-10 h-2 bg-gray-400 rounded-full'></div>
                </div>
                <div className='text-center p-4'>
                    {showWaitingList ? joinWaitlist() : confirmBooking()}
                </div>
            </SwipeableDrawer>
        </Fragment>
    );
};

export default Booking;
