import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router";
import { Checkin } from "../ConfirmBooking/Checkin";

export const Upcoming = () => {
  const navigate = useNavigate();
  const [modalLocation, setModalLocation] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  useEffect(() => {
    setModalLocation(false);
    if (localStorage.getItem("status") == "onGoing") {
      console.log("testt");
      setStatus(true);
    }
    console.log(status);
  }, []);
  const onHandleCheckIn = () => {
    setModalLocation(true);
  };
  return (
    <div>
      <div className="flex text-sm text-gray-500">Today</div>

      <Card sx={{ display: "flex" }} className="shadow-sm mt-2 mb-4">
        <CardMedia
          component="img"
          sx={{ width: "32vw" }}
          src={require("../../assets/images/badminton.jpg")}
          alt="Live from space album cover"
        />
        <Box
          sx={{ display: "flex", flexDirection: "column", width: "68vw" }}
          className="justify-center"
        >
          <CardContent
            style={{ paddingBottom: "0" }}
            className="p-[16px] h-fit"
          >
            <div className="flex justify-between">
              <div className="font-bold">Badminton</div>
              <span
                className={
                  status
                    ? "bg-indigo-100 text-indigo-700 rounded-full px-2 py-1 text-xs font-semibold"
                    : "bg-green-100 text-green-700 rounded-full px-2 py-1 text-xs font-semibold"
                }
              >
                {status ? "Ongoing" : "Confirmed"}
              </span>
            </div>

            <div className="leading-tight text-sm"> 19 Oct 2022</div>

            <div className="mt-2 leading-tight text-sm text-gray-600">
              Court 02 | 7:00am - 8:00am
            </div>
            <div className="leading-tight text-sm text-gray-600">
              Bishan Sports Hall
            </div>
            {status ? (
              <button
                disabled
                className="btn w-full btn-primary text-white rounded-md mt-2 mb-4 "
                onClick={() => onHandleCheckIn()}
              >
                Check-In
              </button>
            ) : (
              <button
                className="btn w-full btn-primary text-white rounded-md mt-2 mb-4 "
                onClick={() => onHandleCheckIn()}
              >
                Check-In
              </button>
            )}
          </CardContent>
        </Box>
      </Card>

      <div className="flex text-sm text-gray-500">This week</div>
      <Card sx={{ display: "flex" }} className="shadow-sm mt-2 mb-4">
        <CardMedia
          component="img"
          sx={{ width: "32vw" }}
          src={require("../../assets/images/gym.jpg")}
          alt="Live from space album cover"
        />
        <Box
          sx={{ display: "flex", flexDirection: "column", width: "68vw" }}
          className="justify-center"
        >
          <CardContent
            style={{ paddingBottom: "16px" }}
            className="p-[16px] h-fit"
          >
            <div className="flex justify-between">
              <div className="font-bold">Gym</div>
              <span className="bg-green-100 text-green-700 rounded-full px-2 py-1 text-xs font-semibold">
                Confirmed
              </span>
            </div>

            <div className="leading-tight text-sm"> 21 Oct 2022</div>

            <div className="mt-2 leading-tight text-sm text-gray-600">
              7:00am - 8:00am
            </div>
            <div className="leading-tight text-sm text-gray-600">
              Ang Mo Kio ActiveSG Gym
            </div>

            <button
              className="btn btn-xs btn-primary w-full btn-outline btn-primary rounded-md mt-3"
              onClick={() => navigate("/")}
            >
              Cancel Booking
            </button>
          </CardContent>
        </Box>
      </Card>
      {modalLocation ? <Checkin closeModal={setModalOpen} /> : null}
    </div>
  );
};
