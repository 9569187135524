import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { ConfirmLocation } from "../components/ConfirmBooking/ConfirmLocation";
import { SwipeableDrawer } from "@mui/material";

const QRscan = () => {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [qrscan, setQrscan] = useState("No result");
    const [showCard, setShowCard] = useState(false);
    const [modalLocationOpen, setModalLocationOpen] = useState(false);
    const handleCancelClick = () => {
        navigate("/mybookings");
    };
    const handleCheckInClick = () => {
        setQrscan("");
        // setShowCard(false);
        setModalLocationOpen(true);
        setOpenDrawer(false);
    };

    return (
        <div>
            <h5 className='grid text-center px-5 pt-10 font-bold text-xl'>
                Facility Check-in
            </h5>
            <h5 className='grid text-center px-5 py-2 font-medium'>
                Scan the facility QR code{" "}
            </h5>
            <div className='mt-6'>
                {openDrawer ? (
                    <p className='text-center text-primary text-2xl mt-60'>
                        Location found!
                    </p>
                ) : (
                    <div className='relative'>
                        <img
                            className='absolute z-10 pt object-scale-down h-48 w-96 mt-20 inset-x-0 mx-auto'
                            src={require("../assets/images/qrBorder.png")}
                            alt='frame'
                        />
                        <QrReader
                            className='z-0'
                            onResult={(result, error) => {
                                if (!!result) {
                                    setQrscan(result?.text);
                                    setShowCard(true);
                                    setOpenDrawer(true);
                                }
                                if (!!error) {
                                    console.info(error);
                                }
                            }}
                            constraints={{
                                facingMode: { exact: "environment" },
                            }}
                            style={{ width: "100%" }}
                        />
                    </div>
                )}

                <div className='fixed bottom-0 inset-x-0 flex flex-col'>
                    <p className='pt-2 text-center text-sm'>
                        A confirmation dialog will show up upon successful
                        scanning.
                    </p>
                    <button
                        className='btn btn-primary m-4'
                        onClick={() => navigate("/mybookings")}
                    >
                        Cancel
                    </button>
                </div>
                <SwipeableDrawer
                    anchor='bottom'
                    open={openDrawer}
                    PaperProps={{
                        sx: {
                            borderTopLeftRadius: "0.8rem",
                            borderTopRightRadius: "0.8rem",
                        },
                    }}
                    onOpen={() => setOpenDrawer(true)}
                    onClose={() => setOpenDrawer(false)}
                >
                    <div className='w-full card bg-base-100 shadow-xl rounded-t-lg'>
                        <div className='flex justify-center py-2'>
                            <div className='w-10 h-2 bg-gray-400 rounded-full'></div>
                        </div>
                        <div className='card-body rounded-t-lg'>
                            <h2 className='grid card-title'>Check In</h2>
                            <p className='text-grey-500 text-xs'>
                                Your current location
                            </p>
                            <p className='pt-1 font-medium'>
                                Bishan Sports Hall
                            </p>
                            <p className='text-sm'>
                                5 Bishan Street 14, Singapore 57978
                            </p>
                            <div className='card-actions justify-end'>
                                <button
                                    className='mt-2 btn w-full btn-primary text-white rounded-md'
                                    onClick={() => handleCheckInClick()}
                                >
                                    Confirm Check In
                                </button>
                                <button
                                    className='w-full bg-white text-black font-bold py-2 px-4 '
                                    onClick={() => setOpenDrawer(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
            {modalLocationOpen ? <ConfirmLocation /> : null}
        </div>
    );
};

const Results = () => {};

export default QRscan;
