import React from "react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

export const ConfirmLocation = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    toast.success("Successfully Checked In!")
    localStorage.setItem("status", "onGoing")
    navigate("/mybookings");
  };
  return (
    <div
      className="fixed w-screen h-screen bg-[rgba(100,100,100,0.9)] top-0 left-0 overflow-auto z-90 transition ease-in-out delay-300"
      style={{ zIndex: 99 }}
    >
      <div className="flex justify-center items-center h-full w-full">
        <div className="flex-column mx-8 rounded-lg w-full h-fit bg-white shadow-lg">
          <div className="flex justify-center align-center items-center content-center">
            <DoneOutlinedIcon className="absolute mb-20 rounded-full text-5xl bg-[#3CB893] text-white border-2 border-white border-double" />
            <div className="font-semibold text-center self-center text-lg px-8 w-full mt-12">
              Check In Successful
            </div>
          </div>

          <div className="px-4">
            <div className="text-gray-600 text-sm">Check In Time</div>
            <div className=" text-sm">9:41 AM</div>
            <div className="text-gray-600 text-sm mt-4">Check In Location</div>
            <div className=" text-sm">Active SG Our Tampines Hub</div>
            <div className=" text-sm">
              1 Tampines Walk, #07-31, Singapore 528523
            </div>
          </div>

          <div className="flex w-full flex-wrap px-4">
            <button
              className="w-full rounded-lg text-white bg-[#DB4A4A] p-4 my-4"
              onClick={() => handleOnClick(false)}
            >
              Confirm check-in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
