import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const navItems = [
    {
        value: "/home",
        icon: <HomeOutlinedIcon />,
        label: "Home",
    },
    {
        value: "/mybookings",
        icon: <CalendarMonthOutlinedIcon />,
        label: "My Bookings",
    },
    {
        value: "/profile",
        icon: <PermIdentityIcon />,
        label: "Profile",
    },
];

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentIndex = navItems.findIndex(
        (item) => item.value === location.pathname
    );
    const [currentTab, setCurrentTab] = React.useState(currentIndex);

    const handleNavigate = (event, newValue) => {
        const index = navItems.findIndex((item) => item.value === newValue);
        setCurrentTab(index);
        navigate(newValue);
    };

    return (
        <div className='flex justify-center px-4 pt-20'>
            <BottomNavigation
                className='z-50 fixed bottom-0 m-4 w-11/12 bg-navbar drop-shadow-md rounded-3xl '
                onChange={handleNavigate}
            >
                {navItems.map((item, index) => (
                    <BottomNavigationAction
                        showLabel={true}
                        key={index}
                        value={item.value}
                        label={item.label}
                        icon={item.icon}
                        className={index === currentTab ? "text-primary" : ""}
                    />
                ))}
            </BottomNavigation>
        </div>
    );
};

export default Navbar;
