import React from 'react'
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {useNavigate} from "react-router";

export const Pending = () => {
  const navigate = useNavigate();
  return (
    <div>

      <div className="flex text-gray-500 text-sm">This week</div>
      <Card sx={{ display: "flex" }} className="shadow-lg mt-2 mb-4">
        <CardMedia
          component="img"
          sx={{ width: "32vw" }}
          src={require("../../assets/images/badminton.jpg")}
          alt="Live from space album cover"
        />
        <Box sx={{ display: "flex", flexDirection: "column", width: "68vw" }} className="justify-center">
            <CardContent style={{paddingBottom: '16px'}} className="p-[16px] h-fit">
            <div className="flex justify-between">
              <div className="font-bold">Badminton</div>
              <span className="bg-[#FFF6D8] text-[#E1B000] rounded-full px-2 py-1 text-xs font-semibold">
                In waitlist
              </span>
            </div>

            <div className="leading-tight text-sm"> 1 Nov 2022</div>
            <div className="mt-2 leading-tight text-sm text-gray-600">Court 01 | 9:00am - 10:00am</div>
            <div className="leading-tight text-sm text-gray-600">Bishan Sports Hall</div>
            <button className="btn btn-xs w-full btn-outline btn-warning rounded-md mt-3">
              Leave waiting list
            </button>
          </CardContent>
        </Box>
      </Card>

      <Card sx={{ display: "flex" }} className="shadow-lg my-4">
        <CardMedia
          component="img"
          sx={{ width: "32vw" }}
          src={require("../../assets/images/gym.jpg")}
          alt="Live from space album cover"
        />
        <Box sx={{ display: "flex", flexDirection: "column", width: "68vw" }} className="justify-center">
            <CardContent style={{paddingBottom: '16px'}} className="p-[16px] h-fit">
            <div className="flex justify-between">
              <div className="font-bold">Gym</div>
              <span className="bg-[#FFF6D8] text-[#E1B000] rounded-full px-2 py-1 text-xs font-semibold">
                In waitlist
              </span>
            </div>

            <div className="leading-tight text-sm"> 5 Nov 2022</div>
            <div className="mt-2 leading-tight text-sm text-gray-600">7:00am - 8:00am</div>
            <div className="leading-tight text-sm text-gray-600">Bishan ActiveSG Gym</div>
            <button className="btn btn-xs w-full btn-outline btn-warning rounded-md mt-3">
              Leave waiting list
            </button>
          </CardContent>
        </Box>
      </Card>
   
    </div>
  )
}
