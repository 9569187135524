import { Routes, Route } from "react-router";
import Layout from "./components/Layout";
import Booking from "./pages/Booking";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { MyBookings } from "./pages/MyBookings";
import Profile from "./pages/Profile";
import CreditRules from "./pages/CreditRules";
import Singpass from "./pages/Singpass";
import { Location } from "./pages/Location";
import RatingHistory from "./pages/RatingHistory";
import WeeklyLimit from "./pages/WeeklyLimit";
import QrScan from "./pages/QrScan";

function App() {
    return (
        <Layout>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/home' element={<Home />} />
                <Route path='/booking/:id' element={<Booking />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/creditRules' element={<CreditRules />} />
                <Route path='/singpass' element={<Singpass />} />
                <Route path='/mybookings' element={<MyBookings />} />
                <Route path='/checkin' element={<Location />} />
                <Route path='/ratinghistory' element={<RatingHistory />} />
                <Route path='/weeklylimit' element={<WeeklyLimit />} />
                <Route path='/qrscan' element={<QrScan />} />
            </Routes>
        </Layout>
    );
}

export default App;
