import React, { Fragment } from "react";
import { useLocation } from "react-router";
import Navbar from "./Navbar";
import Toast from "./Toast";

const Layout = ({ children }) => {
    const location = useLocation();
    let showNavbar = true;
    if (
        location.pathname === "/" ||
        location.pathname === "/singpass" ||
        location.pathname === "/qrscan"
    ) {
        showNavbar = false;
    }
    return (
        <Fragment>
            <div className='container min-h-screen mx-auto'>{children}</div>
            {showNavbar && <Navbar />}
            <Toast />
        </Fragment>
    );
};

export default Layout;
